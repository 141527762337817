import React, { Component } from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  display: none;
  @media(${props => props.theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr 3fr 4fr 2fr;
    grid-gap: 2rem;
    margin-bottom: 2rem;
    padding: 1rem 0;
    border-bottom: 1px solid;
  }
`;

class ProductListTableHeader extends Component {
  render() {
    return (
      <Holder>
        <small>Vendor</small>
        <small>Product</small>
        <small>Description</small>
      </Holder>
    )
  }
}

export default ProductListTableHeader;
