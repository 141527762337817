import React from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 10rem 0;
`;

function CTAHolder({children}) {
    return (
      <Holder>{children}</Holder>
    )
}

export default CTAHolder;
