import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import ImageFade from '../atoms/ImageFade';
import Container from '../atoms/Container';

const List = styled.div`
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin: 10rem 0;
    @media(${props => props.theme.breakpoints.md}) {
      grid-template-columns: repeat(4, 1fr);
    }
    > :first-child {
      grid-column: span 2;
      margin-bottom: 2rem;
      @media(${props => props.theme.breakpoints.md}) {
        grid-column: span 4;
        margin-bottom: 6rem;
      }
    }
    p {
      margin: 0;
    }
`;

const Tile = styled.div`
  a {
    text-decoration: none;
    display: block;
    height: 100%;
    position: relative;
    h3 {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      right: 1rem;
      margin: 0;
      @media(${props => props.theme.breakpoints.sm}) {
        bottom: 1.5rem;
        left: 1.5rem;
        right: 1.5rem;
      }
    }
  }
`;

class CategoryList extends Component {
  render() {
    return (
      <Container>
        <StaticQuery
          query={graphql`
            query CategoryListQuery {
              allContentfulCategory(sort: {order: ASC, fields: order}) {
                nodes {
                  title
                  id
                  slug
                  image {
                    fluid(maxWidth: 1000) {
                        sizes
                        src
                        srcSet
                        aspectRatio
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            return (
              <List>
                <p className="h1">Product Categories</p>
                {data.allContentfulCategory.nodes.map( node => (
                  <Tile key={node.id}>
                    <Link to={'/category/' + node.slug}>
                      {node.image && <ImageFade positioning="absolute">
                        <Img
                          style={{
                            height: 0,
                            paddingBottom: '80%',
                          }}
                          fluid={node.image.fluid}/>
                      </ImageFade>}
                      <h3 className="h5">{node.title}</h3>
                    </Link>
                  </Tile>
                ) )}
              </List>
            )
          }}/>
      </Container>
    )
  }
}

export default CategoryList;
