import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const Holder = styled.div`
  width: 100%;
  max-width: ${props => props.width};
`;

const Inner = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  a {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
    small {
      margin-bottom: 0.5rem;
    }
  }
`;

class VendorLogo extends Component {
  render() {
    const { vendor, showTitle, base, width, center } = this.props;
    return (
      <Holder width={width}>
        <Inner>
          <Link to={`/${base}/${vendor.slug}`}>
            {showTitle && vendor.title && <small>{vendor.title}</small>}
            {vendor.logo && <Img
              style={{
                width: '100%',
                height: '100%',
              }}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: `${center ? 'center' : 'top'} center`,
              }}
              fluid={vendor.logo.fluid}/>}
          </Link>
        </Inner>
      </Holder>
    )
  }
}

VendorLogo.propTypes = {
  vendor: PropTypes.shape( {
    title: PropTypes.string,
    slug: PropTypes.string,
    logo: PropTypes.shape( {
      fluid: PropTypes.object,
    } ),
  } ).isRequired,
  showTitle: PropTypes.bool,
  base: PropTypes.string,
  width: PropTypes.string,
  center: PropTypes.bool,
};

VendorLogo.defaultProps = {
  showTitle: false,
  base: 'vendor',
  width: '10rem',
  center: false,
};

export default VendorLogo;
