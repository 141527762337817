import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import VendorLogo from '../atoms/VendorLogo';

const Holder = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-gap: 2rem;
  margin: 2rem 0;
  padding-bottom: 2rem;
  border-bottom: 1px dotted ${props => props.theme.colours.darkGrey};
  @media(${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr 3fr 4fr 2fr;
  }
`;

const Cell = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  a { text-decoration: none; }
  &:first-child { display: none; }
  @media(${props => props.theme.breakpoints.sm}) {
    b { display: none; }
    &:first-child { display: block; }
    &:last-child { justify-self: end; }
  }
`;

class ProductPreview extends Component {

  state = {
    vendor: null,
  };

  getVendor = (productId, vendors, vendor) => {
    if(vendor) {
      this.setState({
        vendor: vendor
      })
    } else if( vendors ) {
      const result = vendors.nodes.filter(node => {
        if(node.products) {
          return node.products.filter( product => product.id === productId ).length > 0;
        } else {
          return null;
        }
      });

      if ( result.length > 0 ) {
        this.setState({
          vendor: result[0]
        })
      }
    }
  };

  componentDidMount() {
    this.getVendor(this.props.product.id, this.props.vendors, this.props.vendor);
  }

  componentDidUpdate(prevProps) {
    if (this.props.product !== prevProps.product) {
      this.getVendor(this.props.product.id, this.props.vendors, this.props.vendor);
    }
  }

  render() {
    const { product } = this.props;
    return (
      <Holder>
        <Cell>
          {this.state.vendor && <VendorLogo vendor={this.state.vendor}/>}
        </Cell>
        <Cell>
          <p><Link to={'/product/' + product.slug}><b>{this.state.vendor && this.state.vendor.title} - </b>{product.title}</Link></p>
        </Cell>
        <Cell>
          <p className="small"><Link to={'/product/' + product.slug}>{product.excerpt && product.excerpt.excerpt}</Link></p>
        </Cell>
        <Cell>
          <Link className="button" to={'/product/' + product.slug}>More Info</Link>
        </Cell>
      </Holder>
    )
  }
}

ProductPreview.propTypes = {
  product: PropTypes.shape( {
    title: PropTypes.string,
    id: PropTypes.string,
    slug: PropTypes.string,
    excerpt: PropTypes.shape( {
      excerpt: PropTypes.string,
    } ),
  } ).isRequired,
  vendors: PropTypes.object,
  vendor: PropTypes.object,
};

export default ProductPreview;
