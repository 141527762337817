import React from 'react';
import SEO from '../components/molecules/SEO';
import CategoryList from '../components/organisms/CategoryList';
import PreHead from '../components/molecules/PreHead';
import CTATile from '../components/molecules/CTATile';
import Container from '../components/atoms/Container';
import FeaturedProducts from '../components/organisms/FeaturedProducts';
import { graphql } from 'gatsby';
import CTAHolder from '../components/molecules/CTAHolder';

function IndexPage( { data } ) {

  const { homeHeroText, homeVendorTileText, homeTrainingTileText, homeNewsTileText } = data.contentfulGlobalElements;

  return (
    <>
      <SEO title="Home"/>
      <PreHead
        homePage
        text={homeHeroText.homeHeroText}/>

      <CategoryList/>

      <Container>
        <CTAHolder>
          <CTATile
            buttonText="View our Vendors"
            text={homeVendorTileText.homeVendorTileText}
            url="/vendors"
            image="hero_00002.jpg"/>
          <CTATile
            buttonText="Training"
            text={homeTrainingTileText.homeTrainingTileText}
            url="/training-events"
            switchAlignment
            image="hero_00017.jpg"/>
          <CTATile
            buttonText="View our News"
            text={homeNewsTileText.homeNewsTileText}
            url="/news"
            image="hero_00003.jpg"/>
        </CTAHolder>
      </Container>

      <FeaturedProducts/>
    </>
  )
};

export default IndexPage;

export const pageQuery = graphql`
    query {
        contentfulGlobalElements(title: {eq: "Global elements"}) {
            homeHeroText {
                homeHeroText
            }
            homeNewsTileText {
                homeNewsTileText
            }
            homeTrainingTileText {
                homeTrainingTileText
            }
            homeVendorTileText {
                homeVendorTileText
            }
        }
    }
`;
