import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import SharpImage from '../atoms/SharpImage';

const Holder = styled.div`
  position: relative;
  @media(${props => props.theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 4rem;
  }
`;

const TextHolder = styled.div`
  background-color: #000;
  padding: ${props => props.smallText ? '2rem' : '4rem'};
  position: relative;
  z-index: 2;
  @media(${props => props.theme.breakpoints.md}) {
    grid-column: ${props => props.switchAlignment ? '6/13' : '1/8'};
    ${props => props.smallText && 'grid-column: 1/9;'};
    grid-row: 1/2;
    padding: ${props => props.smallText ? '6rem' : '8rem'};
    margin-top: 4rem;
  }
  @media(${props => props.theme.breakpoints.lg}) {
    grid-column-end: span 7;
    margin-top: 8rem;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const ImageHolder = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;
  z-index: 1;
  @media(${props => props.theme.breakpoints.md}) {
    height: auto;
    grid-column: ${props => props.switchAlignment ? '1/8' : '6/13'};
    ${props => props.smallText && 'grid-column: 7/13;'};
    grid-row: 1/2;
    padding-bottom: 4rem;
  }
  @media(${props => props.theme.breakpoints.lg}) {
    padding-bottom: 8rem;
  }
`;

class CTATile extends Component {
  render() {
    const { buttonText, url, image, text, smallText, switchAlignment } = this.props;
    return (
      <Holder>
        <ImageHolder
          switchAlignment={switchAlignment}
          smallText={smallText}>
          <SharpImage
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            hoverEffect={false}
            imgName={image}/>
        </ImageHolder>
        <TextHolder
          switchAlignment={switchAlignment}
          smallText={smallText}>
          <p className={smallText ? "h4" : "h2"}>{text}</p>
          {buttonText && url && <Link className="button large" to={url}>{buttonText}</Link>}
        </TextHolder>
      </Holder>
    )
  }
}

CTATile.propTypes = {
  buttonText: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  image: PropTypes.string.isRequired,
  smallText: PropTypes.bool.isRequired,
  switchAlignment: PropTypes.bool.isRequired,
};

CTATile.defaultProps = {
  smallText: false,
  switchAlignment: false,
};

export default CTATile;
