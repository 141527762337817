import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Container from '../atoms/Container';
import ProductPreview from '../molecules/ProductPreview';
import ProductListTableHeader from '../molecules/ProductListTableHeader';

const Holder = styled.div`
  margin: 10rem 0;
`;

class FeaturedProducts extends Component {
  render() {
    return (
      <Container>
        <Holder>
          <p className="h1">Featured Products</p>
          <ProductListTableHeader/>
          <StaticQuery
            query={graphql`
            query FeaturedProductsQuery {
              contentfulGlobalElements(title: {eq: "Global elements"}) {
                featuredProducts {
                    id
                    title
                    slug
                    excerpt {
                      excerpt
                    }
                }
              }
              allContentfulVendor {
                  nodes {
                      id
                      title
                      logo {
                          fluid(maxWidth: 1000) {
                              src
                              srcSet
                              sizes
                              aspectRatio
                          }
                      }
                      slug
                      products {
                          id
                      }
                  }
              }
            }
          `}
            render={data => {
              return (
                <>
                  {data.contentfulGlobalElements.featuredProducts.map( node => (
                    <ProductPreview key={node.id} product={node} vendors={data.allContentfulVendor}/>
                  ) )}
                </>
              )
            }}/>
        </Holder>
      </Container>
    )
  }
}

export default FeaturedProducts;
